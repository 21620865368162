// import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Programe from './components/Programe';
import Mission from './components/About';
import Executives from './components/Executives';
import BoardDetail from './components/BoardDetail';
import Board from './components/Board';
import Gallery from './components/Gallery';
import Donate from './components/Donate';
import Grants from './components/Grant';
import News from './components/News';
import NewsDetails from './components/NewsDetail';
import ImageList from './components/ImageList';
import Sheroes from './components/Sheroes';
import GuidingStars from './components/GuidingStars';
import Robotics from './components/Robotic';
import ApplySheroes from './components/ApplySheroes';
import GrantDetail from './components/GrantDetail';
import Volunteer from './components/Volunteer';
import Documnents from './components/Documents';




function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home } />
        <Route path='/contact' Component={Contact}/>
        {/* <Route path='/auth' Component={Auth}/> */}
        <Route path='/program/' Component={Programe}/>
        <Route path='/about' Component={Mission}/>
        <Route path='/about/executives'Component={Executives}/>
        <Route path='/about/board' Component={Board}/>
        <Route path='/about/board-details' Component={BoardDetail}/>
        <Route path='/gallery' Component={Gallery}/>
        <Route path='/donate' Component={Donate}/>
        <Route path='/news' Component={News}/>
        <Route path='/news-detail/:id' Component={NewsDetails}/>
        <Route path='/grants' Component={Grants}/>
        <Route path='/images' Component={ImageList}/>
        <Route path='/sheroes-in-stem' Component={Sheroes}/>
        <Route path='/sheroes-in-stem/apply' Component={ApplySheroes}/>
        <Route path='/guiding-stars' Component={GuidingStars}/>
        <Route path='/robotics' Component={Robotics}/>
        <Route path='/grant-detail' Component={GrantDetail}/>
        <Route path='/volunteer'Component={Volunteer}/>
        <Route path='/documnents' Component={Documnents}/>
        
        {/* <Route path='/' Component={}/> */}
        {/* <Route path='/' Component={}/> */}
        {/* <Route path='/' Component={}/> */}
        {/* <Route path='/' Component={}/> */}

               
      </Routes>
    </Router>
  
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

export default App;